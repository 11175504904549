<template>
  <div>
    <CCardBody class="pl-0 pr-0">
      <CDataTable :hover="hover" :striped="striped" :border="border" :small="small" :fixed="fixed" :items="items"
        :fields="fields" :dark="dark" :sorter="{ external: true, resetable: false }" :clickable-rows="true"
        @row-clicked="rowClickHandler" :items-per-page="5" class="table_view" :pagination="false"
        @update:sorter-value="onFliterHandler">
        <template v-slot:no-items-view>
          <div>
            <center>No data found.</center>
          </div>
        </template>
        <template #userAction="{ item }">
          <td v-if="item.status == 0" class="tbl-width-60">
            <div class="d-flex">
              <CButton type="button" v-on:click.stop @click="editSpam(item)"
                class="btn btn-outline-primary btn-sm action-btn">
                Mark Spam
              </CButton>
              <span @click="editRow(item)" :id="item.id" v-on:click.stop class="ml-2 mt-1">
                <CIcon size="1xl" name="cil-pencil" class="mb-1 cursor" />
              </span>
              <span class="ml-2 mt-1" @click="deleteRow(item)" :id="item.id" v-on:click.stop>
                <CIcon size="1xl" name="cil-trash" class="mb-1 cursor" style="color: red" />
              </span>
            </div>
          </td>
          <td v-if="item.status == 1" class="tbl-width-60">
            <div class="d-flex">
              <CButton type="button" v-on:click.stop @click="editUnSpam(item)" class="btn btn-outline-danger btn-sm">
                Unmark Spam
              </CButton>

              <span @click="editRow(item)" :id="item.id" v-on:click.stop class="ml-2 mt-1">
                <CIcon size="1xl" name="cil-pencil" class="mb-1 cursor" />
              </span>
              <span class="ml-2 mt-1" @click="deleteRow(item)" :id="item.id" v-on:click.stop>
                <CIcon size="1xl" name="cil-trash" class="mb-1 cursor" style="color: red" />
              </span>
            </div>
          </td>
          <td v-if="item.status == 4" class="tbl-width-60">
            <div class="d-flex">
              <CButton type="button" v-on:click.stop @click="editApproval(item)"
                class="btn btn-outline-success btn-sm px-4">
                Approve
              </CButton>

              <span @click="editRow(item)" :id="item.id" v-on:click.stop class="ml-2 mt-1">
                <CIcon size="1xl" name="cil-pencil" class="mb-1 cursor" />
              </span>
              <span class="ml-2 mt-1" @click="deleteRow(item)" :id="item.id" v-on:click.stop>
                <CIcon size="1xl" name="cil-trash" class="mb-1 cursor" style="color: red" />
              </span>
            </div>
          </td>
        </template>
        <template #deleteAction="{ item }">
          <td class="text-center" v-on:click.stop>
            <span class="mt-1" @click="deleteRow(item)" :id="item.id" v-on:click.stop>
              <CIcon size="1xl" name="cil-trash" class="mb-1 cursor" style="color: red" />
            </span>
          </td>
        </template>
        <template #action="{ item }">
          <td class="d-flex" v-on:click.stop>
            <span @click="editRow(item)" :id="item.id" v-on:click.stop class="ml-2 mt-1">
              <CIcon size="1xl" name="cil-pencil" class="mb-1 cursor" />
            </span>
            <span class="ml-2 mt-1" @click="deleteRow(item)" :id="item.id" v-on:click.stop>
              <CIcon size="1xl" name="cil-trash" class="mb-1 cursor" style="color: red" />
            </span>
          </td>
        </template>

        <template #phone_no="{ item }">
          <td :title="item.phone_no" v-if="item.phone_no == null && item.country_code == null"></td>
          <td :title="item.phone_no" v-else-if="item.phone_no == null && item.country_code != null">
            {{ item.country_code }}
          </td>
          <td :title="item.phone_no" v-else-if="item.phone_no != null && item.country_code == null">
            {{ item.phone_no }}
          </td>
          <td :title="item.phone_no" v-else>
            {{ item.country_code + " " + item.phone_no }}
          </td>
        </template>
          <template #contact_no="{ item }">
          <td :title="item.contact_no" v-if="item.contact_no == null && item.contact_country_code == null"></td>
          <td :title="item.contact_no" v-else-if="item.contact_no == null && item.contact_country_code != null">
            {{ item.contact_country_code }}
          </td>
          <td :title="item.contact_no" v-else-if="item.contact_no != null && item.contact_country_code == null">
            {{ item.contact_no }}
          </td>
          <td :title="item.contact_no" v-else>
            {{ item.contact_country_code + " " + item.contact_no }}
          </td>
        </template>
        <template #user_profile="{ item }">
          <td v-if="item.user_profile">
            {{ item.user_profile.company_name }}
          </td>
          <td v-else></td>
        </template>
        <template #name="{ item }">
          <td :title="item.name">
            {{ item.name == null ? "" : item.name }}
          </td>
        </template>
        <template #company_name="{ item }">
          <td :title="item.company_name">
            {{ item.company_name == null ? "" : item.company_name }}
          </td>
        </template>
        <template #serviceUserName="{ item }">
          <td v-if="item.user">
            {{ item.user.email }}
          </td>
          <td v-else></td>
        </template>
        <template #deviceType="{ item }">
          <td v-if="item.device_type">
            {{ item.device_type.type }}
          </td>
          <td v-else></td>
        </template>
        <template #deviceName="{ item }">
          <td v-if="item.device_type">
            {{ item.device_type.name }}
          </td>
          <td v-else></td>
        </template>
        <template #mtbUserName="{ item }">
          <td v-if="item.user">
            {{ item.user.email }}
          </td>
          <td v-else></td>
        </template>
        <template #mtbDeviceType="{ item }">
          <td v-if="item.device_type">
            {{ item.device_type.type }}
          </td>
          <td v-else></td>
        </template>
        <template #mtbDeviceName="{ item }">
          <td v-if="item.device_type">
            {{ item.device_type.name }}
          </td>
          <td v-else></td>
        </template>
        <template #userName="{ item }">
          <td>
            {{ item.name == null ? "" : item.name }}
          </td>
        </template>

        <template #controller_type="{ item }">
          <td :title="item.controller_type">
            {{ item.controller_type !== null ? item.controller_type : " " }}
          </td>
        </template>
        <template #mtb_type="{ item }">
          <td>
            {{ item.mtb_type == null ? "" : item.mtb_type }}
          </td>
        </template>
        <template #mtb="{ item }">
          <td>
            {{ item.mtb == null ? "" : item.mtb }}
          </td>
        </template>
        <template #mtbDeviceNameOne="{ item }">
          <td>
            {{ item.controller_type == null ? "" : item.controller_type }}
          </td>
        </template>
        <template #mtbDeviceTypeName="{ item }">
          <td>
            {{ item.mtb_type == null ? "" : item.mtb_type }}
          </td>
        </template>
        <template #created_at="{ item }">
          <td>
            {{
                item.created_at == null ? "" : getOnlyDate(item.created_at)
            }}
          </td>
        </template>
        <template #manager_phone="{ item }">
          <td v-if="item.user_profile">
           {{item.user_profile.country_code ? item.user_profile.country_code : ''}} {{ item.user_profile.phone_no }}
          </td>
          <td v-else></td>
        </template>
        <template #region_name="{ item }">
          <td v-if="item.region_name">
            {{ item.region_name.name }}
          </td>
          <td v-else></td>
        </template>

        <template #shipment_date="{ item }">
          <td v-if="item.shipment_date">
            {{
                item.shipment_date == null
                  ? ""
                  : getOnlyDate(item.shipment_date)
            }}
          </td>
          <td v-else></td>
        </template>

        <template #shipmentAction="{ item }">
          <td class="text-center" v-on:click.stop>
            <span class="mt-1" @click="editRow(item)" :id="item.id" v-on:click.stop>
              <CIcon size="1xl" name="cil-pencil" class="mb-1 cursor" />
            </span>
          </td>
        </template>
        <template #action_pdf="{ item }">
          <td v-if="item.is_download == 1" class="text-center" v-on:click.stop>
            <span class="mt-1" @click="downloadPDF(item)" :id="item.id" v-on:click.stop>
              <em v-b-tooltip.hover title="PDF Download" class="fa fa-file-pdf-o  fa-2x cursor" aria-hidden="true"></em>
            </span>
          </td>

             <td v-else-if="item.is_download == 0" class="text-center" v-on:click.stop>
            <span class="mt-1"  :id="item.id" v-on:click.stop>

              <em  class="fa fa-file-pdf-o  fa-2x cursor disabled-em-element" aria-hidden="true"></em>
            </span>
          </td>

             <td v-else class="text-center" v-on:click.stop>
            <span class="mt-1"  :id="item.id" v-on:click.stop>

              <em  class="fa fa-times  fa-2x cursor disabled-em-element" aria-hidden="true"></em>
            </span>
          </td>
        </template>
        <template #contact_email="{ item }">
          <td class="" :title="item.contact_email">
            <div>
              <span class="mb-0 contactField"> {{ item.contact_email == null ? "" : item.contact_email }}
              </span>
            </div>
          </td>
        </template>
        <template #email="{ item }">
          <td class="" :title="item.email">
            <div>
              <span class="mb-0 contactField"> {{ item.email == null ? "" : item.email }}
              </span>
            </div>
          </td>
        </template>
        <template #customer_region_name="{ item }">
          <td v-if="item.region_name">
            {{ item.region_name }}
          </td>
          <td v-else></td>
        </template>

        <template #customer_action="{ item }">
          <td v-if="item.status == 1">
            <center> <em class="fa fa-check" aria-hidden="true"></em></center>
          </td>
          <td v-else></td>
        </template>

        <template #warranty_nc_serial_no="{ item }">
          <td >
            {{item.is_download == 2 ? item.rejected_nc_serial_no : item.nc_serial_no }}
          </td>
        </template>

         <template #customer_contact_no="{ item }">
          <td >
           {{item.contact_country_code ? item.contact_country_code + " " : ''  }}{{item.contact_no ? item.contact_no : ''}}
          </td>
        </template>

        <template #created_by_email="{ item }">
          <td class="" :title="item.users.email">
            <span class="mb-0 contactField">{{ item.users.email == null ? "" : item.users.email }}</span>
          </td>
        </template>

        <template #customer_checkbox-header>
          <b-form-checkbox class="text-center" id="'checkbox-All'" v-model="allSelected" name="'checkbox-All'" @input="selectAll()" :disabled="checkboxVisible">
          </b-form-checkbox>
        </template>

        <template v-slot:customer_checkbox="{ item }" >
          <td v-on:click.stop @click.native.stop>
            <b-form-checkbox  class="text-center"  v-if="item.status == 0" :id="'checkbox-' + item.id" v-model="item.selected"  @click.native.stop
              :name="'checkbox-' + item.id" @input="selectedInvisualCustomer(item.selected)"></b-form-checkbox>
          </td>
        </template>

        <template #service_action="{ item }">
          <td>
           {{item.status == 0 ? "Pending" : "Processed"}}
          </td>
        </template>

      </CDataTable>
    </CCardBody>
    <div class="text-center d-flex" v-if="pagination">
      <div class="ml-1">
        <b-form-select v-model="selected" @change="tableCountChanged(selected)" :options="optionsTableRows">
        </b-form-select>
      </div>

      <div class="pl-0 class-paginate">
        <b-pagination class="float-left ml-1" v-model="currentPage" :total-rows="Math.ceil(total / selected) * perPage"
          :per-page="perPage" :hide-goto-end-buttons="true" aria-controls="ctable" @change="onPaginationClickEvent">
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { toLocaleStringDate, roundUpMinutes, getOnlyDate } from "@/shared/utils";
import _ from "lodash";
export default {
  name: "CustomTableUser",
  data() {
    return {
      toLocaleStringDate: toLocaleStringDate,
      roundUpMinutes: roundUpMinutes,
      getOnlyDate: getOnlyDate,
      page: 1,
      selected: 50,
      optionsTableRows: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 },
      ],
      currentPage: 1,
      allSelected: false,
      checkedItems: [],
      headerCheckBoxVisible:false,
    };
  },
  created() {
    this.currentPage = this.currentPagination;
  },
  watch: {
    currentPagination: function () {
      this.currentPage = this.currentPagination;
    },
    items: function () {
      this.checkedItems = this.items.filter((item) => item.selected == true);
      if (this.checkedItems == 0) {
        this.allSelected = false;
      }
    },
  },
  methods: {
    rowClickHandler(e) {
           this.rowSelectHandler(e);
    },
   checkVisiblityOfCheckbox(){
    let tempVisibility =  _.filter(this.items,(customer) => {
        if(customer.status == 0){
         return false;
        }
      });
    return tempVisibility ? tempVisibility : true;
    },
    onPaginationClickEvent(e) {
      this.allSelected = false;
      this.page = e;
      this.loadDataEvent();
    },
    loadDataEvent: function () {
      this.loadTableData(this.page, this.selected);
    },
    tableCountChanged: function () {
      this.page = 1;
      this.loadDataEvent();
    },
    editRow: async function (e) {
      this.onRowEditHandler(e);
    },
    downloadPDF: async function (e) {
      this.onPdfDownloadHandler(e);
    },
    deleteRow: function (e) {
      this.onRowDeleteHandler(e);
    },
    editSpam: async function (e) {
      this.onRowSpamHandler(e);
    },
    editUnSpam: async function (e) {
      this.onRowUnSpamHandler(e);
    },
    editApproval: async function (e) {
      this.onRowApprovalHandler(e);
    },
    onFliterHandler(e) {
      this.rowFliterHandler(e);
    },
    selectAll() {
      if (this.allSelected) {
        this.items.map(function (currentValue) {
          currentValue.selected = true;
          currentValue.iconSelected = true;
        });
        this.$emit("child-getList", this.items);
      } else {
        this.items.map(function (currentValue) {
        currentValue.selected = false;
        currentValue.iconSelected = false;
        });
        this.$emit("child-getList", this.items);

      }
      this.$emit("is-select-all", this.allSelected);
      this.headerCheckBox(this.allSelected);

    },
    selectedInvisualCustomer(e) {
      if (e == false) {
        this.allSelected = false;
      }
    },
  
  },
  props: {
    currentPagination: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    items: Array,
    fields: {
      type: Array,
    },
    caption: {
      type: String,
      default: "",
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    total: {
      type: Number,
      default: 10,
    },
    loadTableData: {
      type: Function,
      default: () => { },
    },
    rowSelectHandler: {
      type: Function,
      default: () => { },
    },
    onRowEditHandler: {
      type: Function,
      default: () => { },
    },
    onPdfDownloadHandler: {
      type: Function,
      default: () => { },
    },
    onRowDeleteHandler: {
      type: Function,
      default: () => { },
    },
    onRowSpamHandler: { type: Function, default: () => { } },
    onRowUnSpamHandler: { type: Function, default: () => { } },
    onRowApprovalHandler: { type: Function, default: () => { } },
    rowFliterHandler: {
      type: Function,
      default: () => { },
    },
    pagination: {
      type: Boolean,
      default: true
    },
    headerCheckBox: {

      type: Function,

      default: () => [],

    },
    checkboxVisible:{
      type:Boolean,
      default:false
    }
  },

};
</script>
<style>
.tbl-width-35 {
  width: 5rem !important;
}

.tbl-width-10 {
  width: 2rem !important;
}

.tbl-width-5 {
  width: 0.5rem !important;
}

.tbl-width-20 {
  width: 4rem !important;
}

.tbl-width-30 {
  width: 6rem !important;
}

.tbl-width-40 {
  width: 8rem !important;
}

.tbl-width-50 {
  width: 10rem !important;
}

.tbl-width-60 {
  width: 12rem !important;
}

.tbl-width-80 {
  width: 14rem !important;
}

.tbl-width-100 {
  width: 16rem !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #989898 !important;
}

.table-responsive::-webkit-scrollbar {
  height: 5px !important;
}

.tbl-width-65 {
  width: 11rem !important;
}

.action-btn {
  min-width: 96px !important;
}

@media (max-width: 321px) {
  .class-paginate {
    margin-top: 4px !important;
  }
}

.contactField {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: inline-block;
}

.checkbox-index {
  z-index: 1000 !important;
}
.custom-control-trunovercheckbox {
  position: relative !important;
  display: block !important;
  min-height: 1.3125rem !important;
  padding-left: 31px !important;
  z-index: 1000;
}
.disabled-em-element {
  pointer-events: none !important;
  color: #989898 !important;
}
.custom-control {
    padding-left: 1.87rem !important;
}
</style>
